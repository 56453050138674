import React from "react";
import { Container, Image, Navbar, Nav } from "react-bootstrap";
import "./CustomNavbar.css";

import { HiMenu } from "react-icons/hi";
import { FaTwitter, FaDiscord, FaTelegramPlane } from "react-icons/fa";

const CustomNavbar = () => {
  return (
    <div>
      <Navbar expand="lg" fixed="top" className="customNavBarStyle topBar">
        <Container fluid>
          <Navbar.Brand href="/">
            <Image className="navbarImg" src="/assets/Logo-PAC.png"></Image>
            <h4 className="navbarName textBold colorDarkBlue">
              Portugal Ape Club
            </h4>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="navbarToggle-Icon"
          >
            {" "}
            <HiMenu />{" "}
          </Navbar.Toggle>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className=" customNavBarStyle-links">
              <Nav.Link href="/food">Food & Drinks</Nav.Link>
              <Nav.Link href="/lisbon">Lisbon</Nav.Link>
              <Nav.Link
                target="_blank"
                rel="noopener noreferrer"
                hreflang="x-default"
                href="https://twitter.com/PortugalApeClub"
                className="footerScialIcon"
              >
                <FaTwitter></FaTwitter>
              </Nav.Link>
              <Nav.Link
                target="_blank"
                rel="noopener noreferrer"
                hreflang="x-default"
                href="https://discord.gg/877WY77CMy"
                className=""
              >
                <FaDiscord></FaDiscord>
              </Nav.Link>
              <Nav.Link
                target="_blank"
                rel="noopener noreferrer"
                hreflang="x-default"
                href="https://t.me/+iy2R3CiaaWo2NWJl"
                className=""
              >
                <FaTelegramPlane></FaTelegramPlane>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
