import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Lisbon.css";

const Lisbon = () => {
  return (
    <div>
      <Container fluid className="noPadding ">
        <Row className="backgroundBlue homeHeader">
          <Col md={12} className="textCenter noPadding">
            <div className="headerCityDiv">
              <Image
                fluid
                className="headerIllustration"
                src="/assets/Lisboa.png"
              ></Image>
              <div className="headerOverlay">
                <div className="headerOverlayBlue"></div>
                <Image
                  className="headerTilesOverlay"
                  src="/assets/tilesOverlay01.png"
                ></Image>
                <h1 className="headerH1">Lisbon</h1>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="bigMarginTop noPadding">
          <Col md={6} className="cityDescription noPadding">
            <div>
              <h1>Lisbon</h1>
              <h2>City of Fado</h2>
              <p>
                Welcome to Lisbon, the vibrant capital of Portugal, where
                history and modernity blend seamlessly. Nestled along the
                picturesque Tagus River, Lisbon boasts a unique charm with its
                colorful neighborhoods, historic landmarks, and lively culture.
                Stroll through the winding streets of Alfama, the city's oldest
                district, where traditional Fado music fills the air and narrow
                alleys lead to breathtaking viewpoints. Marvel at the
                architectural splendor of the Belém Tower and Jerónimos
                Monastery, symbols of Portugal's Age of Exploration. Savor the
                city's culinary delights, from fresh seafood to the world-famous
                pastéis de nata. With its sunny climate, welcoming locals, and a
                rich tapestry of experiences, Lisbon invites you to uncover its
                endless wonders and create unforgettable memories.
              </p>
            </div>
          </Col>
          <Col md={6} className="cityFirstIllustration noPadding">
            <Image
              fluid
              className=""
              src="/assets/LisboaIllustration.png"
            ></Image>
          </Col>
        </Row>
        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h1>Places to Visit</h1>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/mouraria.jpg"></Image>
            <p>Mouraria</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/terreiropaco.jpg"></Image>
            <p>Terreiro do Paço</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/torrebelem.jpg"></Image>
            <p>Torre de Belem</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/parquenacoes.jpg"></Image>
            <p>Parque das Nações</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/mosteiro.jpg"></Image>
            <p>Mosteiro dos Jerónimos</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/caissodre.jpg"></Image>
            <p>Cais do Sodré</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/baixa.jpg"></Image>
            <p>Baixa de Lisboa</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/avliberdade.jpg"></Image>
            <p>Avenidade Liberdade</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/elevador.jpg"></Image>
            <p>Elevador de Santa Justa</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/pasteisdebelem.jpg"></Image>
            <p>Pasteis de Belem</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/castelosjorge.jpg"></Image>
            <p>Castelo de S. Jorge</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/sintra.jpg"></Image>
            <p>Sintra</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/lxfactory.jpg"></Image>
            <p>LX Factory</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/maat.jpg"></Image>
            <p>MAAT</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/oceanario.jpg"></Image>
            <p>Oceanario</p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/tram.jpg"></Image>
            <p>Tram 28</p>
          </Col>
        </Row>

        <Row className="marginTop">
          <Col md={12} className="textCenter">
            <h1>Where you can eat</h1>
            <p>Trees of our choice in Lisbon.</p>
          </Col>
        </Row>
        <Row className="smallMarginTop">
          <Col md={12} className="textCenter">
            <h2>Restaurants</h2>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/afuri.png"></Image>
            <p>
              <span className="textBold">Afuri </span>
              <br></br>
              <span className="smallText">Asian Restaurant for Sushi</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/alma.png"></Image>
            <p>
              <span className="textBold">Alma</span>
              <br></br>
              <span className="smallText">
                2 Michelin star restaurant with traditional portuguese food
                <br></br>
                (reservation needed)
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/belcanto.png"></Image>
            <p>
              <span className="textBold">Belcanto</span>
              <br></br>
              <span className="smallText">
                2 Michelin star restaurant with traditional portuguese food
                <br></br>
                (reservation needed)
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/boubous.png"></Image>
            <p>
              <span className="textBold">Boubous</span>
              <br></br>
              <span className="smallText">International restaurant</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/cafe_de_saobento.png"
            ></Image>
            <p>
              <span className="textBold">Café de São Bento</span>
              <br></br>
              <span className="smallText">
                Traditional portuguese food restaurant
                <br></br>
                Must try the famous steak
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/casa_do_bacalhau.png"
            ></Image>
            <p>
              <span className="textBold">Casa do Bacalhau</span>
              <br></br>
              <span className="smallText">
                Traditional portuguese food restaurant
                <br></br>
                with codfish dishes
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/estrela_da_bica.png"
            ></Image>
            <p></p>
            <p>
              <span className="textBold">Estrela da Bica</span>
              <br></br>
              <span className="smallText">
                Traditional portuguese food restaurant
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/il_matriciano.png"
            ></Image>
            <p></p>
            <p>
              <span className="textBold">Il Matriciano</span>
              <br></br>
              <span className="smallText">Italian restaurant</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/lupita.png"></Image>
            <p>
              <span className="textBold">Lupita</span>
              <br></br>
              <span className="smallText">Italian restaurant</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/londrina.png"></Image>
            <p>
              <span className="textBold">Taberna Londrina</span>
              <br></br>
              <span className="smallText">
                Traditional portuguese food restaurant
                <br></br>
                Must try the Francesinha
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/ofrade.png"></Image>
            <p>
              <span className="textBold">O Frade</span>
              <br></br>
              <span className="smallText">
                Traditional portuguese food restaurant
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/panda_cantina.png"
            ></Image>
            <p>
              <span className="textBold">Panda Cantina</span>
              <br></br>
              <span className="smallText">
                Asian Restaurant with good Ramen
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/prado.png"></Image>
            <p>
              <span className="textBold">Prado</span>
              <br></br>
              <span className="smallText">
                Traditional portuguese food restaurant
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/ramiro.png"></Image>
            <p>
              <span className="textBold">Ramiro</span>
              <br></br>
              <span className="smallText">
                Traditional portuguese seafood restaurant
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/taberna_ruadasflores.png"
            ></Image>
            <p>
              <span className="textBold">Taberna Rua das Flores</span>
              <br></br>
              <span className="smallText">
                Traditional portuguese food restaurant
              </span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/timeout.jpg"></Image>
            <p>
              <span className="textBold">Timeout Market</span>
              <br></br>
              <span className="smallText">
                The best of portuguese food in one place
              </span>
            </p>
          </Col>

          <Col md={12} className="textCenter smallMarginTop">
            <h2>Cafes</h2>
            <p>
              <span className="smallText">Cafe for breakfast or brunch</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/copenhagen.png"
            ></Image>
            <p>
              <span className="textBold">Copenhagen</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/dear_breakfast.png"
            ></Image>
            <p>
              <span className="textBold">Dear Breakfast</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/the_mill.png"></Image>
            <p>
              <span className="textBold">the Mill</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/la_boulangerie.jpg"
            ></Image>
            <p>
              <span className="textBold">La Boulangerie</span>
            </p>
          </Col>
          <Col md={12} className="textCenter smallMarginTop">
            <h2>Bars</h2>
          </Col>

          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/imprensa.png"></Image>
            <p>
              <span className="textBold">Imprensa</span>
              <br></br>
              <span className="smallText">Cocktail and oyster bar</span>
            </p>
          </Col>

          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image
              fluid
              src="/assets/lisboa/restaurants/monkey_mash.png"
            ></Image>
            <p>
              <span className="textBold">Monkey Mash & Red Frog</span>
              <br></br>
              <span className="smallText">One of the world 50 best bar</span>
            </p>
          </Col>

          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/vino_vero.png"></Image>
            <p>
              <span className="textBold">Vino Vero</span>
              <br></br>
              <span className="smallText">Wine bar</span>
            </p>
          </Col>
          <Col xs={6} sm={6} md={3} className="textCenter">
            <Image fluid src="/assets/lisboa/restaurants/vago.jpg"></Image>
            <p>
              <span className="textBold">Vago</span>
              <br></br>
              <span className="smallText">
                Cocktail bar
                <br></br>
                (First Ape meetup in Portugal)
              </span>
            </p>
          </Col>
        </Row>
        {/* <Col className="noPadding">
            <Image
              fluid
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="backgroundBlue bigPaddingTop">
          <Col className="textCenter colorWhite">
            <h1 className="colorWhite">Lisbon</h1>
          </Col>
        </Row>
        <Row className="backgroundBlue bigPaddingTop">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              fluid
              src="/assets/tilesBottom.png"
            ></Image>
          </Col>
        </Row> */}
        <Row></Row>
        <Row className="bigMarginTop">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Lisbon;
