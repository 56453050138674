import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const gotToNewPage = () => {
    navigate("/lisbon");
  };

  const [apeID, setApeID] = useState("8653");
  const [isMutant, setIsMutant] = useState(false);
  const [apeImg, setApeImg] = useState("");
  const [apeTileName, setApeTile] = useState("");

  const handleMutantChange = () => {
    setIsMutant(!isMutant);
    fetchTileData();
  };

  const fetchApeData = useCallback(async () => {
    if (apeID < 0) setApeID(0);
    if (apeID > 9999) setApeID(9999);
    let response = await fetch(
      "https://ipfs.io/ipfs/QmeSjSinHpPnmXmspMjwiXyN6zS4E9zccariGR3jxcaWtq/" +
        apeID
    );
    if (response == null) {
      response = await fetch(
        "https://ipfs.io/ipfs/QmeSjSinHpPnmXmspMjwiXyN6zS4E9zccariGR3jxcaWtq/" +
          8653
      );
    }
    const parsed = await response.json();
    let bg;
    let fur;
    for (let index = 0; index < parsed.attributes.length; index++) {
      if (parsed.attributes[index].trait_type === "Background") {
        let str = "" + parsed.attributes[index].value;
        bg = str.split(" ").join("");
      }
      if (parsed.attributes[index].trait_type === "Fur") {
        let str = "" + parsed.attributes[index].value;
        fur = str.split(" ").join("");
      }
    }
    let textURL = parsed.image;
    const imgURLArray = textURL.split("//");
    setApeImg(imgURLArray[1]);
    setApeTile("Apes/" + bg + "-" + fur);
  }, [apeID]);

  const fetchMutantData = useCallback(async () => {
    if (apeID < 0) setApeID(0);
    if (apeID > 19600) setApeID(19494);
    let response = await fetch(
      "https://boredapeyachtclub.com/api/mutants/" + apeID
    );
    if (response == null) {
      response = await fetch(
        "https://boredapeyachtclub.com/api/mutants/" + 8653
      );
    }
    const parsed = await response.json();
    let bg;
    let fur;
    for (let index = 0; index < parsed.attributes.length; index++) {
      if (parsed.attributes[index].trait_type === "Background") {
        let str = "" + parsed.attributes[index].value;
        bg = str.split(" ").join("").slice(2);
      }
      if (parsed.attributes[index].trait_type === "Fur") {
        let str = "" + parsed.attributes[index].value;
        fur = str.split(" ").join("").slice(2);
      }
    }
    let textURL = parsed.image;
    const imgURLArray = textURL.split("//");
    setApeImg(imgURLArray[1]);
    setApeTile("Mutants/" + bg + "-" + fur);
  }, [apeID]);

  const fetchTileData = useCallback(async () => {
    if (!isMutant) {
      fetchApeData();
    } else {
      fetchMutantData();
    }
  }, [isMutant, fetchApeData, fetchMutantData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchApeData();
  };
  useEffect(() => {
    fetchTileData();
  }, [fetchTileData]);

  return (
    <div>
      <Container fluid className=" homeHeader">
        <Row>
          <Col className="noPadding">
            <Image
              className="headerImage"
              src="/assets/headerPACLogo.png"
            ></Image>
          </Col>
        </Row>
        <Row className="backgroundBlue">
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className=" backgroundBlue colorWhite paddingTop">
          <Col className="textCenter">
            <h1 className="colorWhite">Welcome to Portugal!</h1>
          </Col>
        </Row>
        <Row className="backgroundBlue">
          <Col md={6}>
            <Image
              fluid
              className="tileSectionBreak"
              src="/assets/doxiaIntro_2.png"
            ></Image>
          </Col>
          <Col md={6}>
            <Image
              fluid
              className="tileSectionBreak"
              src="/assets/cortesaoIntro_2.png"
            ></Image>
          </Col>
        </Row>
        {/* <Row>
          <Col md={6}>
            <Image
              fluid
              className="tileSectionBreak"
              src="/assets/cortesaoSide.png"
            ></Image>
          </Col>
          <Col md={6}>
            <Image
              fluid
              className="tileSectionBreak"
              src="/assets/cortesaoIntro_2.png"
            ></Image>
          </Col>
        </Row> */}
        <Row>
          <Col sm={6} className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
          <Col sm={6} className="noPadding hideMobile">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="bigPaddingTop">
          <Col md={6} className="colDescription">
            <div className="divDescription">
              <h2>Adventures, Castles, Palaces, Forests, Monuments</h2>
              <p>
                Portugal is a treasure trove of history. Discover majestic
                castles and palaces, wander through ancient monuments, and
                explore lush forests steeped in stories from the past. Welcome
                to a land where every corner has a tale to tell!
                <br></br>
              </p>
              <Image fluid src="/assets/castleape.png"></Image>
            </div>
          </Col>

          <Col md={6} className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
            <Image fluid src="/assets/castle.png"></Image>
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="bigPaddingTop">
          <Col
            xs={{ order: "last" }}
            md={{ span: 6, order: "first" }}
            className="noPadding"
          >
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
            <Image fluid src="/assets/beach.png"></Image>
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
          <Col
            xs={{ order: "first" }}
            md={{ span: 6, order: "last" }}
            className="colDescription"
          >
            <div className="divDescription">
              <h2>Sun-kissed Beaches, a lot of waves to surf</h2>
              <p className="">
                As you know, Portugal boasts a stunning coastline dotted with
                beautiful beaches. Here, you can gaze at the horizon and imagine
                our caravels setting sail to explore the world. Whether you're
                looking for adventure or relaxation, Portugal's beaches have
                something for everyone.
                <br></br>
              </p>
              <Image fluid src="/assets/beachape02.png"></Image>
            </div>
          </Col>
        </Row>

        <Row className="bigPaddingTop">
          <Col md={6} className="colDescription">
            <div className="divDescription">
              <h2>Food, Food Everywhere!</h2>
              <p className="">
                As you know, Portuguese cuisine is a true delight and a point of
                pride for our country. From savory seafood dishes like bacalhau
                to the sweet treat of pastéis de nata, our food is sure to
                tantalize your taste buds. Come and experience the rich flavors
                that make Portugal a culinary haven.
                <br></br>
              </p>
              <a href="/food" className="apeLinks">
                Check our Traditional Food & Drinks
              </a>
              <Image fluid src="/assets/foodape.png"></Image>
            </div>
          </Col>

          <Col md={6} className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
            <Image fluid src="/assets/food.png"></Image>
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="bigPaddingTop">
          <Col
            xs={{ order: "last" }}
            md={{ span: 6, order: "first" }}
            className="noPadding"
          >
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
            <Image fluid src="/assets/wine.png"></Image>
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
          <Col
            xs={{ order: "first" }}
            md={{ span: 6, order: "last" }}
            className="colDescription"
          >
            <div className="divDescription">
              <h2>Wine for every taste</h2>
              <p className="">
                Portugal is renowned for its diverse and exquisite wines. From
                the robust reds of the Douro Valley to the crisp Vinho Verde,
                our country offers a wine for every palate. Come and savor the
                unique flavors of Portugal's rich viticultural heritage.
                <br></br>
              </p>
              <Image fluid src="/assets/wineape02.png"></Image>
            </div>
          </Col>
        </Row>

        <Row className="rowGenerativeTiles">
          <Col md={12}>
            <h1 className="textCenter">Portuguese Ape Tiles</h1>
          </Col>
          <Col md={6} className="generativeTilesDescription">
            <div className="divDescription">
              <p className="">
                <br></br>
                Step into the intricate world of Portuguese tiles, where
                artistry meets history. These vibrant ceramic treasures have
                adorned Portugal's streets, palaces, and homes for centuries,
                each tile telling a story of the nation's rich cultural
                tapestry.
                <br></br>
                Now, we invite you to create your own piece of Portuguese
                artistry with our personalized tiles, inspired by the unique
                characteristics of your Ape. Immerse yourself in the beauty and
                tradition of Portuguese tile-making as you craft a keepsake that
                captures the essence of your journey through this enchanting
                land.
                <br></br>
                <br></br>
                Simply input your Ape ID and generate your very own unique
                Portuguese tile. It's the perfect keepsake to remember your
                adventures in Portugal!
                <br></br>
                More surprises to come!
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <Row>
                <Col className=" textCenter">
                  <Image
                    className="tileImg textCenter"
                    src={"https://ipfs.io/ipfs/" + apeImg}
                  ></Image>
                </Col>
                <Col className=" textCenter">
                  <Image
                    className="tileImg  textCenter"
                    src={"/assets/tiles/" + apeTileName + ".png"}
                  ></Image>
                </Col>
              </Row>
              <Row>
                <Col>
                  <form className="formSearch" onSubmit={handleSubmit}>
                    <div className="input-field">
                      <label className="textBold">
                        Ape ID:
                        <input
                          className="inputID"
                          placeholder="Search Ape ID"
                          type="number"
                          value={apeID}
                          onChange={(e) => setApeID(e.target.value)}
                        />
                      </label>
                      <br></br>
                      <label>
                        Is Mutant:
                        <input
                          name="isMutant"
                          type="checkbox"
                          checked={isMutant}
                          onChange={handleMutantChange}
                        />
                      </label>
                    </div>
                  </form>
                  <div className="divDownload">
                    <a
                      className="downloadButton"
                      href={"/assets/tiles/" + apeTileName + ".png"}
                      download="portugueseTile.png"
                    >
                      Download Ape tile
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="backgroundBlue">
          <Col sm={12} className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
          <Col sm={12} className="bigMarginTop textCenter">
            <h1 className="colorWhite">Visiting</h1>
          </Col>
        </Row>
        <Row className="backgroundBlue paddingTop bigPaddingBottom">
          <Col md={4} className="textCenter">
            <div className="cityDiv" onClick={() => gotToNewPage()}>
              <Image
                fluid
                className="cityIllustration"
                src="/assets/Lisboa.png"
              ></Image>
              <div className="cityOverlay">
                <div className="cityOverlayBlue"></div>
                <Image
                  className="tilesOverlay"
                  src="/assets/tilesOverlay01.png"
                ></Image>
                <p className="cityLink">Lisbon</p>
              </div>
            </div>
          </Col>
          <Col md={4} className="textCenter">
            <div className="cityDiv">
              <Image
                fluid
                className="cityIllustration"
                src="/assets/Porto.png"
              ></Image>
              <div className="cityOverlay">
                <div className="cityOverlayBlue"></div>
                <Image
                  className="tilesOverlay"
                  src="/assets/tilesOverlay02.png"
                ></Image>
                <p className="cityLink">Porto</p>
              </div>
            </div>
          </Col>
          <Col md={4} className="textCenter">
            <div className="cityDiv">
              <Image
                fluid
                className="cityIllustration"
                src="/assets/Coimbra.png"
              ></Image>
              <div className="cityOverlay">
                <div className="cityOverlayBlue"></div>
                <Image
                  className="tilesOverlay"
                  src="/assets/tilesOverlay03.png"
                ></Image>
                <p className="cityLink">Coimbra</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
          <Col sm={6} className="noPadding hideMobile">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesTop.png"
            ></Image>
          </Col>
        </Row>
        <Row className="textCenter marginTop">
          <Col>
            <h1>Portuguese Team</h1>
            <p>
              In case you need anything, we are here to help you out!
              <br></br>
              Feel free to hop in our discord or send a dm via twitter. We hope
              you have a great time in Portugal!
            </p>
          </Col>
        </Row>
        <Row className="rowApesTeam">
          <Col xs={6} sm={4} md={2} className="textCenter">
            <Image fluid src="/assets/team/doxia.png"></Image>
            <a
              className="teamName textCenter textBold"
              href="https://twitter.com/Doxia_eth"
            >
              Doxia
            </a>
          </Col>
          <Col xs={6} sm={4} md={2} className="textCenter">
            <Image fluid src="/assets/team/nef.png"></Image>
            <a
              className="teamName textCenter textBold"
              href="https://twitter.com/nef7773"
            >
              Nef
            </a>
          </Col>
          <Col xs={6} sm={4} md={2} className="textCenter">
            <Image fluid src="/assets/team/cortesao.png"></Image>
            <a
              className="teamName textCenter textBold"
              href="https://twitter.com/NCortesao"
            >
              Cortesão
            </a>
          </Col>
          <Col xs={6} sm={4} md={2} className="textCenter">
            <Image fluid src="/assets/team/marco.png"></Image>
            <a
              className="teamName textCenter textBold"
              href="https://twitter.com/MarcoMirandaRod"
            >
              Marco
            </a>
          </Col>
          <Col xs={6} sm={4} md={2} className="textCenter">
            <Image fluid src="/assets/team/maria.png"></Image>
            <a
              className="teamName textCenter textBold"
              href="https://twitter.com/MariadoCarmoPT"
            >
              Maria
            </a>
          </Col>
          <Col xs={6} sm={4} md={2} className="textCenter">
            <Image fluid src="/assets/team/roberto.png"></Image>
            <a
              className="teamName textCenter textBold"
              href="https://twitter.com/rmdmac"
            >
              Roberto
            </a>
          </Col>
        </Row>
        <Row>
          <Col className="noPadding">
            <Image
              className="tileSectionBreak"
              src="/assets/tilesBottom.png"
            ></Image>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <pre>{JSON.stringify(bgTrait, null, 2)}</pre>
            <pre>{JSON.stringify(furTrait, null, 2)}</pre>
            <pre>{JSON.stringify(apeTileName, null, 2)}</pre>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default Home;
